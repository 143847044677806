<template>
  <backoffice-container>
    <div v-if="isLoading">
      <b-spinner
        small
        type="grow"
        variant="primary"
        class="d-block mx-auto mt-3"
      />
    </div>

    <div v-else-if="hasErrors">
      <div class="mt-3 text-danger text-center">
        {{ $t("error-message.error-message") }}
      </div>
    </div>

    <div v-else>
      <b-row>
        <b-dropdown
          v-if="canEdit"
          id="dropdown-dropleft"
          variant="link"
          class="d-lg-none edit-icon-mobile"
          dropleft
          toggle-class="pl-0"
          style="position: absolute; top: 184px; right: 0"
          no-caret
        >
          <template #button-content>
            <feather-icon icon="SettingsIcon" size="20" class="text-primary" />
          </template>
          <b-dropdown-text class="bg-light">
            <p class="user-name font-weight-bolder mb-0">
              {{ $t("edit-dropdown.title") }}
            </p>
          </b-dropdown-text>
          <b-dropdown-item @click="isEditModalVisible = true">
            {{ $t("edit-dropdown.details") }}
          </b-dropdown-item>
          <!-- <b-dropdown-item v-if="isStaff" @click="isRolesModalVisible = true">
            {{ $t('members.roles.edit') }}
          </b-dropdown-item> -->
          <b-dropdown-item
            v-if="canEdit"
            @click="isClassifiersModalVisible = true"
          >
            {{ $t("members.edit.classifiers.name") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canEdit"
            @click="isCustomFieldsModalVisible = true"
          >
            {{ $t("members.edit.custom-fields.label") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canEdit"
            @click="isOnboardingResponsesModalVisible = true"
          >
            {{ $t("members.edit.inscription-form.label") }}
          </b-dropdown-item>
        </b-dropdown>

        <!-- Left Column: banner, avatar, name and posts -->
        <b-col lg="7">
          <profile-details
            :name="member.name ? `${member.name} ${member.surname}` : '--- ---'"
            :headline="member.headline"
            :brief-info="member.organizations"
            :avatar-placeholder="avatarPlaceholder"
            :profile-image="member.avatarURL"
            :header-background="member.backgroundUrl"
            fit-profile-image
            :has-relation="hasRelation"
            :has-action-buttons="hasActionButtons"
            :can-edit="canEdit"
            :member="member"
            @removed-relation="handleConnectRelation"
            @request-connection="wantConnection = true"
            @open-chat="handleSidebarChange"
            @request-meeting="handleCalendarMeetingOpen"
            @edit="handleEditProfile"
          />
          <!-- Edit modal -->
          <member-edit-modal
            v-model="isEditModalVisible"
            :member="member"
            :classifiers="classifiers"
            :is-backoffice="$route.name === 'backoffice-members-details'"
            @profile-updated="handleProfileUpdated"
          />
          <!-- Mobile container -->
          <component
            :is="member.status == 3 ? 'b-button' : 'b-card'"
            v-if="currentMember && currentMember.isMember"
            block
            :class="bgColor"
            class="text-center db-block d-lg-none mb-2"
            :style="member.status == 3 ? 'border-radius: 1.2rem; padding: 1.5rem' : ''"
            @click="member.status == 3 ? isRequestedModalOpened = true : ''"
          >
            <h3 class="mb-0 font-weight-bolder">
              {{ getStatus }}
            </h3>
          </component>

          <!-- Request accept decline -->
          <b-row 
            v-if="statusMember[member.status] === this.$t('backoffice.members.requested-join')" 
            class="flex-wrap mb-2 d-lg-none"
          >
            <b-col>
              <b-button
                block
                variant="success"
                @click="submitAnswerRequest('accept')"
              >
                <feather-icon icon="CheckIcon" class="mr-25" />
                {{ $t('backoffice.members.modals.accept-request') }}
              </b-button>
            </b-col>
            <b-col>
              <b-button block variant="danger" @click="submitAnswerRequest('reject')">
                <feather-icon icon="XIcon" class="mr-25" />
                {{ $t('backoffice.members.modals.reject-request') }}
              </b-button>
            </b-col>
          </b-row>
          <!-- Validate attendance -->
          <b-button
            v-if="member.status === 3"
            block
            variant="primary"
            class="d-block mx-auto mb-2 font-weight-bolder d-lg-none"
            @click="validateAttendance()"
          >
            <span class="d-flex justify-content-center">
              <feather-icon icon="CheckIcon" size="23" class="mr-25" />
              <h4 class="text-white mb-0">
                {{ $t("backoffice.members.attendance") }}
              </h4>
            </span>
          </b-button>

          <!-- Attendance history -->
          <div v-if="member.status === 3">
            <div v-for="(attend, index) in attendance" :key="index">
              <b-alert
                :show="index > attendance.length-4"
                variant="primary"
                class="text-center d-lg-none"
              >
                {{ $t("backoffice.members.attendance-message") }}
                {{ getDate(attend) }}
              </b-alert>
            </div>
          </div>

          <!-- Passport QR -->
          <b-button
            v-if="member.status === 3"
            block
            variant="warning"
            class="d-block mx-auto mb-2 font-weight-bolder d-lg-none"
            @click="isModalOpened = true"
          >
            <span class="d-flex justify-content-center">
              <b-icon
                icon="upc-scan"
                scale="1.2"
                variant="white"
                class="mr-50"
              />
              <h4 class="text-white mb-0">
                {{ $t("backoffice.passport") }}
              </h4>
            </span>
          </b-button>

          <!-- About card (small devices) -->
          <about-member
            :member="member"
            :classifiers="classifiers"
            class="d-lg-none"
            :has-relation="true"
            @profile-updated="handleProfileUpdated"
          />
          
          <!-- Member Spaces -->
          <b-card
            v-if="enabledApps.subcommunities"
            class="mt-2"
            :title="$t('members.my-communities')"
          >
            <div v-if="member.subCommunities && member.subCommunities.length > 0">
              <div
                v-for="community in member.subCommunities"
                :key="community.key"
                class="mb-75"
              >
                <b-link @click="communityLocation(community.community)">
                  <collective-logo :logo="communityLogo(community)" />
                  <span class="font-weight-bold ml-50">{{translateTranslationTable($store.state.locale.currentLocale, community.community.name)}}
                    <small><b-badge pill :variant="getColor(community.community.mainType)" class="ml-25">{{community.community.mainType}}</b-badge></small>
                  </span>
                </b-link>
              </div>
            </div>
            <div v-else>
              <div
                class="d-flex flex-column justify-content-center align-items-center mb-1"
              >
                <img
                  class="placeholder placeholder__small"
                  :src="communitiesPlaceholder"
                />
                <p class="text-primary font-weight-bold">
                  {{ $t("members.no-activity-message") }}
                </p>
              </div>
            </div>
          </b-card>

          <!-- Connections -->
          <b-card v-show="showConnections">
            <member-connections
              v-if="member"
              :member="member"
              :is-staff="isStaff"
              :isMySelf="false"
              :want-connection="wantConnection"
              @relations="getRelations"
              @wantConnections="wantConnection = false"
            />
          </b-card>

          <!-- Member posts -->
          <list-container-widget
            v-if="enabledApps.posts"
            id="posts-list"
            class="posts-list"
            :app-id="13"
            :view-more-location="getLocation('posts-list')"
            stretch
          >
            <posts-list :user-key="member.userKey" />
          </list-container-widget>
        </b-col>

        <b-col>
          <!--About card in big devices -->
          <b-dropdown
            v-if="canEdit"
            id="dropdown-dropleft"
            variant="link"
            right
            class="edit-icon d-none d-lg-block"
            no-caret
            :style="aviso ? moreMarg : menosMarg"
          >
            <template #button-content>
              <feather-icon
                icon="SettingsIcon"
                size="20"
                class="text-primary edit-icon"
                :style="aviso ? moreMarg : menosMarg"
              />
            </template>
            <b-dropdown-text class="bg-light">
              <p class="user-name font-weight-bolder mb-0">
                {{ $t("edit-dropdown.title") }}
              </p>
            </b-dropdown-text>
            <b-dropdown-item @click="isEditModalVisible = true">
              {{ $t("edit-dropdown.details") }}
            </b-dropdown-item>
            <b-dropdown-item v-if="userProfile" @click="goSettings()">
              {{ $t("backoffice.settings.title") }}
            </b-dropdown-item>
            <!-- <b-dropdown-item v-if="isStaff" @click="isRolesModalVisible = true">
              {{ $t('members.roles.edit') }}
            </b-dropdown-item> -->
            <b-dropdown-item
              v-if="canEdit"
              @click="isClassifiersModalVisible = true"
            >
              {{ $t("members.edit.classifiers.name") }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canEdit"
              @click="isCustomFieldsModalVisible = true"
            >
              {{ $t("members.edit.custom-fields.label") }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canEdit"
              @click="isOnboardingResponsesModalVisible = true"
            >
              {{ $t("members.edit.inscription-form.label") }}
            </b-dropdown-item>

          </b-dropdown>

          <component
            :is="member.status == 3 ? 'b-button' : 'b-card'"
            v-if="currentMember && currentMember.isMember"
            block
            :class="bgColor"
            class="text-center d-none d-lg-block mb-2"
            :style="member.status == 3 ? 'border-radius: 1.2rem; padding: 1.5rem' : ''"
            @click="member.status == 3 ? isRequestedModalOpened = true : ''"
          >
            <h3 class="mb-0 font-weight-bolder">
              {{ getStatus }}
            </h3>
          </component>
          <b-row v-if="statusMember[member.status] === this.$t('backoffice.members.requested-join')" class="flex-wrap mb-2 d-none d-lg-flex">
            <b-col>
              <b-button
                block
                variant="success"
                @click="submitAnswerRequest('accept')"
              >
                <feather-icon icon="CheckIcon" class="mr-25" />
                {{ $t('backoffice.members.modals.accept-request') }}
              </b-button>
            </b-col>
            <b-col>
              <b-button block variant="danger" @click="submitAnswerRequest('reject')">
                <feather-icon icon="XIcon" class="mr-25" />
                {{ $t('backoffice.members.modals.reject-request') }}
              </b-button>
            </b-col>
          </b-row>
           <b-button
            v-if="member.status === 3"
            block
            variant="primary"
            class="mx-auto mb-2 font-weight-bolder d-none d-lg-block"
            @click="validateAttendance()"
          >
            <span class="d-flex justify-content-center">
              <feather-icon icon="CheckIcon" size="23" class="mr-25" />
              <h4 class="text-white mb-0">
                {{ $t("backoffice.members.attendance") }}
              </h4>
            </span>
          </b-button>
          <div v-if="member.status === 3">
            <div v-for="(attend, index) in attendance" :key="index">
              <b-alert
                :show="index > attendance.length-4"
                variant="primary"
                class="text-center d-none d-lg-block"
              >
                {{ $t("backoffice.members.attendance-message") }}
                {{ getDate(attend) }}
              </b-alert>
            </div>
          </div>
          <!-- QR -->
          <b-button
            v-if="member.status === 3"
            block
            variant="warning"
            class="mx-auto mb-2 font-weight-bolder d-none d-lg-block"
            @click="isModalOpened = true"
          >
            <span class="d-flex justify-content-center">
              <b-icon
                icon="upc-scan"
                scale="1.2"
                variant="white"
                class="mr-50"
              />
              <h4 class="text-white mb-0">
                {{ $t("backoffice.passport") }}
              </h4>
            </span>
          </b-button>
          <about-member
            :member="member"
            :classifiers="classifiers"
            class="d-none d-lg-block"
            :has-relation="true"
            @profile-updated="handleProfileUpdated"
          />
          <!-- Twitter -->
          <div v-if="hasTwitterFeed" class="vh-75 mb-2">
            <twitter-feed-widget :src="twitterSrc" />
          </div>
          <!--User Activity-->
          <!-- <b-card :title="$t('activity.title')">
            <div class="mt-1">
              <div class="d-flex flex-column justify-content-center align-items-center mb-1">
                <img class="placeholder" :src="activityPlaceholder">
                <p class="mt-2 text-primary font-weight-bold">
                  {{ $t("members.no-activity-message") }}
                </p>
              </div>
            </div>
          </b-card> -->
          <!-- Subcommunities -->
          <!-- Exception for MATCOM -->
          <b-card
            v-if="enabledApps.subcommunities || currentCollective.key ==='453c6a00-4972-11ed-8713-134667e4e27c'"
            class="mt-2"
            :title="currentCollective.key ==='453c6a00-4972-11ed-8713-134667e4e27c' ? $t('settings.activities') : $t('members.my-communities')"
          >
            <div
              v-if="member.subCommunities && member.subCommunities.length > 0"
            >
              <div
                v-for="community in member.subCommunities"
                :key="community.key"
                class="mb-75"
              >
                <b-link @click="communityLocation(community)">
                  <collective-logo :logo="communityLogo(community)" />
                  <span class="font-weight-bold ml-50">{{
                    translate(community.name || community.community.name)
                  }}</span>
                </b-link>
              </div>
            </div>
            <div v-else>
              <div
                class="d-flex flex-column justify-content-center align-items-center mb-1"
              >
                <img
                  class="placeholder placeholder__small"
                  :src="communitiesPlaceholder"
                >
                <p class="text-primary font-weight-bold">
                  {{ $t("members.no-activity-message") }}
                </p>
              </div>
            </div>
          </b-card>
        </b-col>

        <!-- Request meeting modal -->
        <request-meeting-modal
          v-model="isRequestMeetingVisible"
          :member="member"
        />

        <!-- sortedClassifiers -->
        <classifiers-modal
          v-model="isClassifiersModalVisible"
          :item-key="member.key != null ? member.key : member"
          morph-type="member"
          :classifiers="classifiers"
          model-type="member"
          @profile-updated="handleProfileUpdated"
        />

        <!-- Custom fields modal -->
        <customfields-modal
          v-model="isCustomFieldsModalVisible"
          :item="member"
          morph-type="member"
          @profile-updated="handleProfileUpdated"
        />

        <!-- Onboarding responses modal -->
        <onboarding-responses-modal
          v-model="isOnboardingResponsesModalVisible"
          :item="member"
          morph-type="member"
          @profile-updated="handleProfileUpdated"
        />

        <!-- Roles modal -->
        <b-modal
          v-model="isRolesModalVisible"
          :title="$t('members.roles.edit')"
          :ok-title="$t('form.actions.save')"
          no-close-on-backdrop
          @ok="setRoles"
        >
          <b-form-group v-if="isStaff">
            <label for="profile-role"> {{ $t("members.roles.title") }}</label>
            <v-select
              id="type"
              v-model="rolesSelected"
              multiple
              :placeholder="$t('form.select-placeholder')"
              :options="rolesOptions"
              label="name"
            />
          </b-form-group>
        </b-modal>
      </b-row>
    </div>

    <!-- Answer Requested -->
    <b-modal
      v-if="member"
      id="requested-modal"
      v-model="isRequestedModalOpened"
      :title="$t('backoffice.members.answer-request')"
      :ok-title="$t('form.actions.save')"
      size="sm"
      hide-footer
    >
      <b-form-group>
        <div v-if="member.status != 3 && member.status != 4">
          <label class="mt-25 font-weight-bold">{{ $t('backoffice.members.select-request') }}:</label>
          <v-select
            v-model="answerRequest"
            class="select-control p-0 mb-1"
            :clearable="false"
            :searchable="false"
            label="name"
            :options="requestedOptions"
          />
        </div>
        <p class="font-weight-bolder mt-1 text-center">
          {{ member.name }} {{ member.surname }} ({{ member.email }})
        </p>
        <div class="text-center mb-2">
          <b-button
            class="font-weight-bold"
            :variant="member.isMember && member.status == 3 ? 'danger' : 'primary'"
            @click="toggleIsMember()"
          >
            {{ member.isMember && member.status === 3 ? $t('backoffice.members.disable') : member.status != 3 && member.status != 4 ? $t('backoffice.members.send-answer') : $t('backoffice.members.accept') }}
          </b-button>
        </div>
      </b-form-group>
    </b-modal>

    <!-- QR passport -->
    <b-modal
      id="qr-modal"
      v-model="isModalOpened"
      :title="$t('backoffice.passport')"
      size="lg"
      hide-footer
      @hidden="isCopied = false"
    >
      <passport 
        :logged-member="member" 
        :is-modal="true" 
      />
    </b-modal>
  </backoffice-container>
</template>

<script>
import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import CommunitiesPlaceholder from '@/assets/images/placeholders/light/communities.svg';
import PostsList from '@/views/apps/posts/components/PostsList.vue';
import Service from '@/config/service-identifiers';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import ProfileDetails from '@core/components/profile/ProfileDetails.vue';
import TwitterFeedWidget from '@/views/apps/twitter/widgets/TwitterFeedWidget.vue';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { CHAT_ACTIONS } from '@/views/apps/chat/constants/chat-store-constants';
import AboutMember from '@core/components/members/AboutMember.vue';
import MemberEditModal from '@/views/apps/member/modals/MemberEditModal.vue';
import RequestMeetingModal from '@/views/apps/member/modals/RequestMeetingModal.vue';
import CustomfieldsModal from '@core/components/modal/CustomfieldsModal.vue';
import OnboardingResponsesModal from '@core/components/modal/OnboardingResponsesModal.vue';
import ClassifiersModal from '@core/components/modal/ClassifiersModal.vue';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import CollectiveLogo from '@core/components/collective-logo/CollectiveLogo.vue';
import vSelect from 'vue-select';
import Passport from '@core/components/members/Passport.vue';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { randomString } from '@/@core/utils/utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import MemberConnections from "@/views/apps/member/components/MemberConnections.vue";

export default {
  name: 'MemberDetails',
  components: {
    PostsList,
    ProfileDetails,
    TwitterFeedWidget,
    AboutMember,
    MemberEditModal,
    RequestMeetingModal,
    ClassifiersModal,
    CustomfieldsModal,
    OnboardingResponsesModal,
    ListContainerWidget,
    CollectiveLogo,
    vSelect,
    BackofficeContainer,
    Passport,
    MemberConnections
  },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  data() {
    return {
      member: null,
      isLoading: true,
      hasErrors: false,
      isChatVisible: false,
      isEditModalVisible: false,
      isRequestMeetingVisible: false,
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      isOnboardingResponsesModalVisible: false,
      isRolesModalVisible: false,
      rolesSelected: [],
      rolesOptions: [],
      isOpenedUnsubscribeModal: false,
      aviso: false,
      isRequestedModalOpened: false,
      answerRequest: '',
      camposVacios: {},
      isModalOpened: false,
      hasRelation: false,
      keyAbout: 'keyAbout',
      wantConnection: false,
    };
  },
  computed: {
    moreMarg() {
      return {
        'margin-top': '-60px',
      };
    },
    menosMarg() {
      return {
        'margin-top': '-30px',
      };
    },
    attendance() {
      return this.$store.getters.attendance.paginated[1];
    },
    twitterSrc() {
      const twitterLink = (this.member?.links || []).find(
        ({ site }) => site === 'Twitter',
      );
      return twitterLink?.url;
    },
    hasTwitterFeed() {
      return (
        this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].twitter
        && this.twitterSrc
      );
    },
    currentUser() {
      return this.$store.getters.loggedUser;
    },
    currentMember() {
      return this.$store.getters.loggedMember;
    },
    hasActionButtons() {
      return this.member?.key !== this.currentMember?.key;
    },
    userProfile() {
      if (this.member?.key == this.currentMember?.key) {
        return true;
      }
      return false;
    },
    requestedOptions() {
      return [
        { name: this.$t('backoffice.members.modals.accept-request'), value: true },
        { name: this.$t('backoffice.members.modals.reject-request'), value: false },
      ];
    },
    canEdit() {
      if (this.member != null && this.currentMember != null) {
        return (
          this.member?.key === this.currentMember.key
          || this.$store.getters.currentCollective.isStaff
        );
      }
      return this.$store.getters.currentCollective.isStaff;
    },
    avatarPlaceholder() {
      return MembersPlaceholder;
    },
    communitiesPlaceholder() {
      return CommunitiesPlaceholder;
    },
    // classifiersData() {
    //   if (this.$store.getters.classifiers.myMember) {
    //     return this.$store.getters.classifiers.myMember.unpaginated;
    //   }
    //   return [];
    // },
    // classifiers() {
    //   return this.classifiersData;
    // },
    classifiersData() {
      console.log('classifiersData this.member', this.member);
      if(this.member?.classifiers){
        return this.member.classifiers;
      }
      if (this.$store.getters.classifiers.myMember) {
        return this.$store.getters.classifiers.myMember.unpaginated;
      }
      return [];
    },
    classifiers() {
      console.log('classifiersData', this.classifiersData);
      return this.classifiersData.reduce((acc, current) => {
        const x = acc.findIndex((item) => item.key === current.key);
        if (x === -1) {
          return acc.concat([current]);
        }
        acc[x] = current;
        return acc;
      }, []);
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    communityRoles() {
      return this.$store.getters.roles?.unpaginated;
    },
    memberRoles() {
      return this.$store.getters.memberRoles?.unpaginated;
    },
    enabledApps() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps];
    },
    username() {
      return this.$route.params.username;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    canUnsuscribe() {
      return this.loggedMember?.username;
    },
    getStatus() {
      return this.statusMember[this.member.status];
    },
    statusMember() {
      return {
        0: this.$t('backoffice.members.pending'),
        1: this.$t('backoffice.members.requested-join'),
        2: this.$t('backoffice.members.rejected'),
        3: this.$t('backoffice.members.confirmed'),
        4: this.$t('backoffice.members.rejected-admin'),
        5: this.$t('backoffice.members.rejected-user'),
        6: this.$t('backoffice.members.left'),
        7: this.$t('backoffice.members.invited'),
        8: this.$t('backoffice.members.waiting-list'),
        81: this.$t('backoffice.members.waiting-form'),
      };
    },
    canVerified() {
      return this.statusMember[this.member.status] === this.$t('backoffice.members.requested-join')
      || this.statusMember[this.member.status] === this.$t('backoffice.members.confirmed')
      || this.statusMember[this.member.status] === this.$t('backoffice.members.rejected-admin');
    },
    optionsEnableMember() {
      return {
        0: this.$t('backoffice.members.pending'),
        1: this.$t('backoffice.members.requested-join'),
        3: this.$t('backoffice.members.confirmed'),
        7: this.$t('backoffice.members.invited'),
      };
    },
    bgColor() {
      const colorMember = {
        0: 'bg-light',
        1: 'bg-warning',
        2: 'bg-secondary',
        3: 'bg-success',
        4: 'bg-danger',
        5: 'bg-dark',
        6: 'secondary',
        7: 'bg-primary',
        8: 'bg-info',
        81: 'bg-dark',
      };
      return colorMember[this.member.status];
    },
    showConnections() {
      return true;
    }
  },
  watch: {
    async username() {
      await this.fetchUser(this.username);
      await this.fetchClassifiers();
      await this.getMemberRoles();
      this.rolesSelected = this.memberRoles;
      this.getRoles();
      await this.isComplete();
    },
    classifiers(newValue) {
      return newValue;
    },
    hasRelation() {
      this.keyAbout = randomString(10);
    },
  },

  async created() {
    //Check QR if it is staff
    if (!this.currentCollective.isStaff) {
      window.location.href = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace('{slug}', this.currentCollective.slug).replace(
        '{subdomain}',
        'app',
      )}/members/${this.username}?from_qr`;
      this.isLoading = false;
    }

    console.log(`fetching member with username: ${this.username}`);
    const member = await this.fetchUser(this.username);
    console.log('member', member);
    await this.getAttendance(); //TODO: poner esto en algún modal
    await this.fetchClassifiers();
    await this.getMemberRoles();
    await this.fetchDataFriends(this.currentMember.key);  //TODO: poner esto en algún modal

    this.rolesSelected = this.memberRoles;
    this.getRoles();
    await this.isComplete();
    this.answerRequest = this.requestedOptions[0];
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    async toggleIsMember() {
      this.isRequestedModalOpened = false;
      const isMember = this.member.status == 1 ? this.member.isMember : !this.member.isMember;
      try {
        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'backoffice/memberStatus',
            customName: 'membersBO',
            forceUpdate: true,
            requestConfig: {
              memberKey: this.member.key,
              isMember,
              firstResponse: true,
              status: isMember ? this.answerRequest === this.requestedOptions[0] ? 3 : 4 : 4,
            },
          },
        });
        await this.fetchUser(this.username);
        this.notifySuccess(this.$t('backoffice.members.modals.success-member'));
      } catch {
        this.notifyError(this.$t('backoffice.members.modals.error-member'));
      }
    },
    async fetchDataFriends(memberKey, forceAPICall = true) {
      const response = await this.$store.dispatch('getItems', {
        itemType: 'memberRelation',
        customName: 'myMemberRelationshipsUsingConnects',
        page: this.lastLoadedPage,
        forceAPICall,
        perPage: 16,
        requestConfig: { orderByDate: -1, memberKey },
      });
      this.hasRelation = response.data.filter((item) => item.username === this.username).length > 0;
      this.keyAbout = randomString(10);
    },
    communityLogo(community) {
      return community?.header?.logo_url || community?.community?.logoURL;
    },
    goSettings() {
      this.$router.push({
        name: 'globalMemberSettings',
        params: { username: this.username },
      });
    },
    isComplete() {
      this.aviso = false;
      for (const campo in this.member) {
        if (
          campo !== 'subCommunities'
          && campo !== 'category'
          && campo !== 'customFields'
        ) {
          if (this.member[campo] === null || this.member[campo] === '') {
            this.camposVacios[campo] = this.member[campo];
            this.aviso = true;
          }
        }
      }
    },
    async fetchUser(username) {
      // console.log('fetchUser...', this.fetchUser);
      this.updateBreadcrumbs();
      this.isLoading = true;
      this.hasErrors = false;

      // get space
      // console.log('this.$route.params.communityId', this.$route.params.communityId);
      const currentSpaceSlug = this.$store.getters.currentCollective.slug;
      // console.log('currentSpaceSlug', currentSpaceSlug);

      try {
        // TODO: use member store
        const response = await this.$store.$service[Service.BackendClient].get(
          '/communityMembers',
          {
            params: {
              communitySlug: currentSpaceSlug,
              username,
              isForProfile: true,
              isForBackoffice: 1,
            },
          },
        );

        const member = response.data.data[0];

        //TODO: mejor poner un mensaje de miembro no encontrado
        if (!member) {
          this.$router.push({
            name: 'error',
            params: {
              communityId: this.$route.params.communityId,
              error: '404',
            },
          });

          return;
        }

        // Si se encuentra, actualizar todo
        this.member = member;

        this.updateBreadcrumbs();
        this.isLoading = false;
        return member;
      } catch {
        this.hasErrors = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('error-message.load-failed'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    handleSidebarChange(isVisible) {
      if (isVisible) {
        this.$store.dispatch(CHAT_ACTIONS.openIndividualChat, {
          user: this.member,
        });
      } else {
        this.$store.dispatch(CHAT_ACTIONS.closeIndividualChat);
      }
    },
    handleEditProfile() {
      this.isEditModalVisible = true;
    },
    async handleProfileUpdated() {
      await this.fetchUser(this.username);
      this.fetchClassifiers();
      this.isComplete();
    },
    handleCalendarMeetingOpen() {
      this.isRequestMeetingVisible = true;
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: 'members.breadcrumb-text.members-list',
          to: { name: 'backoffice-members' },
        },
        {
          text: this.member?.name
            ? `${this.member.name} ${this.member.surname}`
            : 'members.breadcrumb-text.member-details',
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    async fetchClassifiers() {
      await this.$store.dispatch('getItems', {
        itemType: 'backoffice/classifiers',
        customName: 'classifiers',
        storedKey: 'myMember',
        page: 1,
        forceAPICall: true,
        requestConfig: {
          memberKey: this.member?.key,
          count: 1000,
        },
      });
      this.isLoading = false;
    },
    async getAttendance() {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'communities/attendance/onsite',
        customName: 'attendance',
        page: 1,
        forceAPICall: true,
        requestConfig: {
          memberKey: this.member?.key,
        },
      });
      this.isLoading = false;
    },
    communityLocation(event) {
      window.location.href = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}',
        event?.community?.subdomain || event?.subdomain || 'app',
      ).replace('{slug}', event?.slug || event?.community?.slug);
    },
    getMemberRoles() {
      return this.$store.dispatch('getRoles', { memberKey: this.member?.key });
    },
    async getRoles(page) {
      const response = await this.$store.dispatch('getRoles', {
        page,
        perPage: 15,
      });
      this.rolesOptions = response.data;
    },
    setRoles() {
      // Removed roles
      const removedRoles = [];
      this.memberRoles.forEach((item) => (!this.rolesSelected.includes(item) ? removedRoles.push(item.key) : null));
      // Assigned Roles
      const keys = [];
      this.communityRoles.map(({ key }) => {
        this.rolesSelected.map((item) => (key === item.key ? keys.push(key) : null));
      });
      return this.$store.dispatch('setRoles', {
        memberKey: this.member?.key,
        roleKeys: keys,
        deleteRoleKeys: removedRoles,
      });
    },
    handleUnsuscribe() {
      this.isOpenedUnsubscribeModal = true;
    },
    unsubscribeCommunity() {
      this.$store.dispatch('unsubscribeCommunity', {
        communityKey: this.currentCollective.key,
      });
      this.logOut();
    },
    logOut() {
      this.$store.dispatch('logout');
    },
    async validateAttendance() {
      try {
        await this.$store.dispatch('postItem', {
          type: '/communities/attendance/onsite',
          requestConfig: {
            memberKey: this.member.key,
          },
        });
        this.notifySuccess(this.$t('backoffice.members.success'));
        await this.getAttendance();
      } catch {
        this.notifyError(this.$t('backoffice.members.messages.import-error'));
      }
    },
    async sendAnswerRequested() {
      this.isRequestedModalOpened = false;
      try {
        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'backoffice/memberStatus',
            customName: 'membersBO',
            requestConfig: {
              memberKey: this.member.key,
              status: this.answerRequest === this.requestedOptions[0] ? 3 : 4,
            },
          },
        });
        this.fetchUser(this.username);
        this.notifySuccess(this.$t('backoffice.members.modals.success-status'));
      } catch {
        if (this.membersError !== '') {
          this.notifyError(`${this.$t('backoffice.settings.messages.capacity-members-error')}`);
        } else {
          this.notifyError(this.$t('backoffice.members.modals.error-status'));
        }
      }
    },
    getDate(attendance) {
      if (this.attendance != null) {
        return new Date(attendance?.attendanceDate).toLocaleString();
      }
      return '';
    },
    // async handleRemovedRelation(response) {
    //   if (response === true) {
    //     await this.fetchUser(this.username);
    //     this.hasRelation = false;
    //     this.wantConnection = false
    //   }
    // },
    async handleConnectRelation(response) {
      await this.fetchUser(this.username);
      if (response === true) {
        this.hasRelation = true;
        this.wantConnection = true;
      } else {
        this.hasRelation = false;
        this.wantConnection = false;
      }
    },
    submitAnswerRequest(answer) {
      const [accept, reject] = this.requestedOptions;
      if (answer === 'accept') {
        this.answerRequest = accept;
      } else {
        this.answerRequest = reject;
      }
      this.toggleIsMember();
    },
    getRelations(relations) {
      this.hasRelation = relations.filter(item => item.key === this.loggedMember.key).length > 0;
      this.keyAbout = randomString(10);
      // this.wantConnection =false
    },
  },
};

</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/page-profile.scss";
.placeholder {
  height: 160px;
}
.edit-icon {
  position: absolute;
  right: 10px;
  top: 38px;
  cursor: pointer;
}
.edit-icon-mobile {
  top: -27px;
}
.dropdown-member {
  [dir] .dropdown-menu[x-placement="bottom-start"],
  [dir] .dropdown-menu[x-placement="bottom-end"] {
    margin-top: 50 !important;
  }
}
.alerta2 {
  display: none;
}
@media (max-width: 991px) {
  .alerta {
    display: none;
  }
  .alerta2 {
    display: block;
  }
}
</style>
