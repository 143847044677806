<template>
  <div>
    <b-modal
      :title="$t('members.edit.edit-member')"
      :visible="visible"
      size="lg"
      footer-class="d-none"
      @show="resetForm"
      @change="$emit('change', $event)"
    >
      <!-- Body -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            :name="$t('members.edit.name.name')"
            rules="required|max:255"
          >
            <b-form-group
              :label="$t('members.edit.name.label')"
              label-for="profile-name"
              class="font-weight-bold"
            >
              <b-form-input
                id="profile-name"
                v-model="profile.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('members.edit.name.placeholder')"
                :disabled="isSaving"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Surname -->
          <validation-provider
            #default="validationContext"
            :name="$t('members.edit.surname.name')"
            rules="required|max:255"
          >
            <b-form-group
              :label="$t('members.edit.surname.label')"
              label-for="profile-surname"
              class="font-weight-bold"
            >
              <b-form-input
                id="profile-surname"
                v-model="profile.surname"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('members.edit.surname.placeholder')"
                :disabled="isSaving"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Headline -->
          <validation-provider
            #default="validationContext"
            :name="$t('members.edit.headline.name')"
            rules="max:255"
          >
            <b-form-group>
              <label for="profile-headline" class="font-weight-bold">
                {{ $t("members.edit.headline.label") }}
                <b-button
                  v-if="!individualChat"
                  v-b-tooltip.hover.right
                  :title="$t('members.edit.headline.tooltip')"
                  variant="link"
                  class="p-0 link-button"
                  @click="isInputDescriptionModalVisible = true"
                >
                  <feather-icon icon="InfoIcon" size="16" />
                </b-button>
              </label>
              <description-input
                v-model="isInputDescriptionModalVisible"
                :title="$t('members.edit.headline.label')"
                :description="$t('members.edit.headline.tooltip')"
              />

              <b-form-input
                id="profile-headline"
                v-model="profile.headline"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('members.edit.headline.placeholder')"
                :disabled="isSaving"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Type -->
          <template v-if="isBackoffice">
            <label for="type" class="font-weight-bold">
              {{ $t("people.type") }}</label>
            <v-select
              v-model="memberType"
              name="type"
              :clearable="false"
              :searchable="false"
              label="name"
              :get-option-label="
                (a) => a.name[currentLocale] || Object.values(a.name)[0]
              "
              :options="types"
              class="mb-2"
            />
          </template>
          <!-- Organizations -->
          <b-form-group
            v-if="enabledOrganizationApp"
            :label="$t('members.edit.organizations.label')"
            label-for="profile-organizations"
            class="font-weight-bold"
          >
            <members-select
              v-model="profile.organizations"
              clearable
              multiple
              searchable
              dir="ltr"
              input-id="profile-organizations"
              :fetch-data="fetchData"
              label="name"
            />
            <div v-if="staff" class="text-right mt-1">
              <b-button
                v-b-modal.modal-create-organizations
                variant="primary"
                size="sm"
                class="mb-1"
              >
                <feather-icon icon="PlusCircleIcon" size="14" />
                {{ $t("organizations.create.add-organization") }}
              </b-button>
            </div>
          </b-form-group>
          <!-- Summary -->
          <validation-provider
            #default="validationContext"
            :name="$t('members.edit.summary.name')"
          >
            <b-form-group
              :label="$t('members.edit.summary.label')"
              label-for="profile-summary"
              class="font-weight-bold"
            >
              <plain-editor
                id="profile-summary"
                v-model="profile.summary"
                class="form-control text-editor"
                :placeholder="$t('members.edit.summary.placeholder')"
                :disabled="isSaving"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Locations array -->
          <validation-provider
            v-slot="validationContext"
            :name="$t('organizations.edit.locations.name')"
          >
            <b-form-group
              :label="$t('organizations.edit.locations.label')"
              label-for="profile-location"
              class="font-weight-bold"
            >
              <places-map-select
                v-model="profile.locations"
                :placeholder="$t('organizations.edit.locations.placeholder')"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Links -->
          <b-form-group
            :label="`${$t('members.edit.links.label')}`"
            label-for="profile-links"
            class="font-weight-bold"
          >
            <!-- Label Attribute is "site" because "text" can be null -->
            <form-couples
              v-model="profile.links"
              label-id="profile-links"
              trim
              :value-placeholder="$t('members.edit.links.url-placeholder')"
              :label-placeholder="$t('members.edit.links.label-placeholder')"
              label-attribute="label"
              :are-links="true"
              value-attribute="url"
              :disabled="isSaving"
            >
              <template
                #label="{
                  id,
                  value,
                  setValue,
                  placeholder,
                  classNames,
                  disabled,
                  onEnter,
                }"
              >
                <b-form-select
                  :id="id"
                  :value="value || 'Website'"
                  :options="linkLabelsOptions"
                  :placeholder="placeholder"
                  :class="classNames"
                  :disabled="disabled"
                  label-type="site"
                  @input="setValue"
                  @keydown.enter.prevent="onEnter"
                />
              </template>
            </form-couples>
          </b-form-group>
          <!-- Avatar -->
          <b-form-group
            :label="$t('members.edit.avatar.label')"
            label-for="profile-avatar"
            class="font-weight-bold"
          >
            <file-preview
              v-if="profile.avatarURL"
              :src="getImageResource(profile.avatarURL)"
              :placeholder-image="avatarPlaceholder"
              mime-type="image"
              cancelable
              @cancel="profile.avatarURL = null"
            />
            <file-upload
              v-else
              v-model="avatar"
              type="image"
              class="mw-100"
              cancelable
              :placeholder="$t('members.edit.avatar.placeholder')"
              :drop-placeholder="$t('members.edit.avatar.drop-placeholder')"
              @cancel="avatar = null"
            />
          </b-form-group>
          <b-form-group
            :label="$t('members.edit.background.label')"
            label-for="profile-background"
            class="font-weight-bold"
          >
            <file-preview
              v-if="profile.backgroundURL"
              :src="getImageResource(profile.backgroundURL)"
              :placeholder-image="backgroundPlaceholder"
              mime-type="image"
              cancelable
              @cancel="profile.backgroundURL = null"
            />
            <file-upload
              v-else
              v-model="background"
              type="image"
              class="mw-100"
              cancelable
              :placeholder="$t('members.edit.background.placeholder')"
              :drop-placeholder="$t('members.edit.background.drop-placeholder')"
              @cancel="background = null"
            />
          </b-form-group>
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isSaving"
            >
              {{ $t("form.actions.save") }}
            </b-button>
            <b-button
              type="reset"
              class="mr-2"
              variant="outline-secondary"
              :disabled="isSaving"
            >
              {{ $t("action.reset") }}
            </b-button>
            <b-button
              variant="outline-secondary"
              size="sm"
              :disabled="isSaving"
              @click="$emit('change', false)"
            >
              {{ $t("action.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- <organization-edit-modal
      v-model="isAddOrganizationModalVisible"
      :is-member-profile="true"
      @created="handleCreate"
    /> -->
    <b-modal
      id="modal-create-organizations"
      centered
      hide-footer
      size="lg"
    >
      <template #modal-header>
        <language-selector-header :title="$t('organizations.modal-create-title')" @closeModal="closeModal" @selectLanguage="(language)=>selectedLanguage = language" />
      </template>
      <membership-event-create-modal
        item-type="organizations"
        :selected="selectedLanguage"
        @close-modal="closeModal"
      />
    </b-modal>
  </div>
</template>

<script>
// NO SE USAN!!!
// import AjaxSelect from '@core/components/ajax-select/AjaxSelect.vue';
// import OrganizationEditModal from '@/views/apps/organization/components/OrganizationEditModal.vue';
// import { translateTranslationTable } from '@/@core/libs/i18n/utils';
// import OrganizationsAPI from '@/views/apps/organization/store/OrganizationsAPI';
// import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';

import { VBTooltip } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import PlainEditor from '@core/components/editor/PlainEditor.vue';
import formValidation from '@core/comp-functions/forms/form-validation';
import Service from '@/config/service-identifiers';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import FormCouples from '@core/components/form-couples/FormCouples.vue';
import ActiveLinksLabels from '@/@core/constants/ActiveLinksLabels';
import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import HeaderPlaceholder from '@/assets/images/placeholders/light/header.jpeg';
import FileUpload from '@core/components/files/FileUpload.vue';
import FilePreview from '@core/components/files/FilePreview.vue';
import vSelect from 'vue-select';
import PlacesMapSelect from '@core/components/places-map-input/PlacesMapSelect.vue';
import { required, max } from '@validations';
import { getImageResource } from '@/@core/utils/image-utils';
import DescriptionInput from '@core/components/layouts/apps-layout/DescriptionInput.vue';
import MembersSelect from '@/views/apps/member/components/MembersSelect.vue';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import MembershipEventCreateModal from '@/views/apps/membershipEvents/components/MembershipEventCreateModal.vue';

export default {
  name: 'MemberEditModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    PlainEditor,
    FileUpload,
    FilePreview,
    FormCouples,
    vSelect,
    LanguageSelectorHeader,
    PlacesMapSelect,
    DescriptionInput,
    MembersSelect,
    MembershipEventCreateModal,
  },
  directives: { BTooltip: VBTooltip },
  mixins: [ToastNotificationsMixin],

  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
    member: {
      type: Object,
      required: true,
    },
    isBackoffice: Boolean,
  },
  data() {
    return {
      profile: {
        avatarURL: null,
        backgroundURL: null,
        name: '',
        surname: '',
        headline: '',
        summary: '',
        organizations: null,
        locations: [],
      },
      avatar: null,
      background: null,

      isSaving: false,

      required,
      max,
      isAddOrganizationModalVisible: false,
      isInputDescriptionModalVisible: false,

      individualChat: false,
      types: [],
      memberType: {},
      enabledOrganizationApp: false,
      selectedLanguage: this.currentLocale,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    username() {
      return this.$route.params.username;
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    linkLabelsOptions() {
      return ActiveLinksLabels.map((label) => ({
        text: this.$t(`links.label.${label}`),
        value: label,
      }));
    },
    avatarPlaceholder() {
      return MembersPlaceholder;
    },
    backgroundPlaceholder() {
      return HeaderPlaceholder;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    staff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
  },
  watch: {
    async username() {
      await this.getLoggedMember();
    },
    member() {
      this.resetForm();
    },
  },
  setup() {
    const { getValidationState } = formValidation(() => {});

    return {
      getValidationState,
    };
  },
  async created() {
    this.resetForm();
    await this.fetchTypes();
    this.memberType = this.member.type;
    await this.getInstalledApps();
    await this.installedMainApps();
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modal-create-organizations');
    },
    async getLoggedMember() {
      await this.installedMainApps();
      const response = await this.$store.$service[Service.BackendClient].get(
        '/communityMembers',
        {
          params: {
            communitySlug:
              this.$store.getters.mainCollective?.slug
              || this.$store.getters.currentCollective.slug,
            username: this.username,
            isForProfile: true,
          },
        },
      );
      this.profile.organizations = this.member.organizations;
    },
    async getInstalledApps() {
      await this.$store.dispatch('getInstalledApps');
    },
    getImageResource,
    async resetForm() {
      this.profile = { ...this.member };
      this.avatar = null;
      this.background = null;
      if (this.$refs.refFormObserver) {
        this.$refs.refFormObserver.reset();
      }
      await this.getLoggedMember();
    },
    async onSubmit() {
      this.isSaving = true;
      try {
        await this.updateProfile();
        this.notifySuccess(this.$t('members.edit.success-message'));
        this.$emit('profile-updated');
        this.$emit('change', false);
      } catch (error) {
        this.notifyError(this.$t('error-message.general-error'));
      }

      this.isSaving = false;
    },
    /**
     * TODO: move to member store module when created.
     */
    async updateProfile() {
      const requestConfig = {
        communityKey: this.$store.getters.currentCollective.key,
        memberKey: this.member.key,
        summary: this.profile.summary,
        name: this.profile.name,
        surname: this.profile.surname,
        headline: this.profile.headline,
        links: (this.profile.links || []).map(({ key, url, label }) => ({
          key,
          url,
          label,
        })),
        isBg: !!this.background,
        deleteBg:
          !!this.member.backgroundURL
          && !this.profile.backgroundURL
          && !this.background,
        isProfilePhoto: !!this.avatar,
        deleteProfilePhoto:
          !!this.member.avatarURL && !this.profile.avatarURL && !this.avatar,
        organizationKey: this.profile.organizations.map(({ key }) => key),
        locations: this.profile.locations,
        typeKey: this.memberType?.key,
      };
      let response;
      if (this.avatar || this.background) {
        const formData = new FormData();
        if (this.avatar) {
          formData.append('avatar', this.avatar);
        }
        if (this.background) {
          formData.append('background', this.background);
        }
        formData.append('data', JSON.stringify(requestConfig));

        response = await this.$store.$service[Service.BackendClient].put(
          'members',
          formData,
          {
            headers: { 'content-type': 'multipart/form-data' },
          },
        );
      } else {
        response = await this.$store.$service[Service.BackendClient].put(
          'members',
          requestConfig,
        );
      }
      return response.data;
    },
    fetchData(page, searchString = '', force = true) {
      const requestConfigOrganizations = {
        isOrganization: true,
        communityParentSlug: this.communitySlug,
      };
      return this.$store.dispatch('getItems', {
        page,
        itemType: 'communities/simply',
        customName: 'shareOrganizations',
        storedKey: 'organizations',
        forceAPICall: force,
        searchString: searchString || '',
        perPage: this.perPage,
        requestConfig: requestConfigOrganizations,
      });
    },
    async fetchTypes() {
      const response = await this.$store.$service[Service.BackendClient].get(
        'types',
        {
          params: {
            communitySlug: this.$store.getters.currentCollective.slug,
            page: 1,
            count: 1000,
            modelType: 'member',
          },
        },
      );
      this.types = response.data.data;
    },
    async installedMainApps() {
      const response = await this.$store.dispatch('mainApps');
      if (response) { this.enabledOrganizationApp = response.data.apps[32] || false; }
    },
    handleCreate() {
      this.getLoggedMember();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-editor::v-deep {
  height: auto;
  .ql-editor {
    min-height: 96px;
  }
}
.select-control {
  flex: 1 1 auto;
}
</style>
