<template>
  <b-modal
			id="form"
			v-model="isOnboardingResponsesModalVisible"
			:title="$t('backoffice.onboarding-form.edit-form')"
			:ok-title="$t('form.actions.save')"
			ok-only
      :visible="visible"
      hide-footer
      centered
      no-close-on-backdrop
			@ok="saveAnswers()"
		>
			<h4 class="text-center mb-1">
        {{ currentItem.name || "--" }} {{ currentItem.surname || "--" }}
      </h4>
			<div v-for="(value, key, index) in currentItem" :key="index" class="mb-1">
				<div v-if="key != 'name' && key != 'surname'">
					<p class="font-weight-bold mb-25">
						{{ key }}
					</p>
					<b-form-input v-if="profile[key].correction" v-model="profile[key].correction" />
					<b-form-input v-else-if="profile[key].response" v-model="profile[key].response" />
					<b-form-input v-else v-model="profile[key]" />
				</div>
			</div>
		</b-modal>
</template>

<script>
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
	name: "OnboardingResponsesModal",
	components: {
	},
	props: {
    item: {
      type: Object,
      required: true,
    },
    visible: Boolean,
    memberKey: {
      type: String,
      default: '',
    },
	},
	data() {
		return {
			isLoading: false,
			answers: [],
			currentItem: "",
			isOnboardingResponsesModalVisible: false,
			profile: {},
		};
	},
	computed: {
		fields() {
			return [
				{
					key: "name",
					label: this.$t("user.title"),
					thClass: "pl-2",
				},
			];
		},
		currentLocale() {
			return this.$store.state.locale.currentLocale;
		},
	},
	watch: {
		fields(value) {
			return value;
		},
	},
  async created() {
    this.loading = true;
    await this.fetchOnboardingResponses();
    this.customFields = this.cFields;
    this.loading = false;
  },
	methods: {
    async fetchOnboardingResponses() {
      await this.$store.dispatch('fetchOnboardingResponses', { memberKey: this.item?.key });
    },
		async saveAnswers() {
			const diff = [];

			delete this.profile.avatarURL;
			delete this.profile.email;
			Object.entries(this.profile).filter((x, index) => {
				if (!Object.values(this.currentItem).includes(x[1])) {
					diff.push(x);
				}
			});
			diff.splice(0, 1);
			const corrections = diff.map((item) => item[1]);
			const formQuestionKeys = [];
			diff.map((item) => {
				this.questions.map((i) => {
					if (Object.values(i.name).includes(item[0])) {
						formQuestionKeys.push(i.key);
					}
				});
			});
			try {
				await this.$store.dispatch("editItem", {
					item: {
						itemType: "/formAnswers",
						customName: "onboardingAnswers",
						requestConfig: {
							formKey: this.formKey,
							formQuestionKeys,
							memberKey: this.profile.key,
							corrections,
							languageCode: this.currentLocale,
						},
					},
				});
				this.$toast({
					component: ToastificationContentVue,
					props: {
						title: this.$t("success-message.general-success-edit"),
						icon: "AlertTriangleIcon",
						variant: "success",
					},
				});
			} catch {
				this.$toast({
					component: ToastificationContentVue,
					props: {
						title: this.$t("error-message.general-error"),
						icon: "AlertTriangleIcon",
						variant: "danger",
					},
				});
			}
		},
	},
};
</script>
